.drawer {
    width: 240px;
    flex-shrink: 0;
  }
  
  .drawerPaper {
    width: 240px;
    background-color: #36393F;
    border-right: none;
  }
  
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
  }
  
  .title {
    flex-grow: 1;
    font-weight: 600;
    margin-left: 16px;
    color: #fff;
  }
  
  .list {
    margin-top: 16px;
  }
  
  .listItem {
    border-radius: 4px;
   
  }
  
  .listItemText {
    font-weight: 600;
    color: #fff;
  }
  .chat--selected{
    opacity: 1 !important;
    background: #19875457;
    border-radius: 10px;
  }
  .profile-img {
    position: relative;
  }
  
  .profile-img img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  
  .profile-img::before {
    content: '\1F4CB';
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .profile-img:hover::before {
    opacity: 1;
  }