body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 14px  !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #5865F2;
  border-radius: 4px;
}

::-webkit-scrollbar-button {
  display: none;
}

.form-control:disabled,
.css-16xfy0z-control[aria-disabled="true"] {
  opacity: 0.7 !important;
}
.react-select__menu {
  background-color: #da0a0a; /* Задайте желаемый цвет фона */
  color: #333; /* Задайте желаемый цвет текста */
  border: 1px solid #ccc; /* Задайте границу */
}