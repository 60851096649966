.managment-table{
    max-width: 1000px;
    margin: 0 auto;
    border-collapse: collapse; 
    vertical-align: baseline;
  text-align: center;
}
.managment-table th {
    padding: 1rem; 
    color: #fff;
    background-color:#5865f2cb;
}
.managment-table td {
  padding: 1rem; 
  background-color: #36393f;
  border: none;
  color: #fff;
  font-weight: 600;
  
}
.managment-table td:nth-of-type(3) {
  color: #999999;
  
}
.managment-table tr,
.managment-table th {
    border-bottom: solid 1px #ffffff33 ;
}
.disabled{
  position: relative;
  color: #86192b !important;
}
.enabled{
  position: relative;
  color: #1ad377!important;
}