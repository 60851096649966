.loader{
    display: flex;
    align-items: center;
    height: 100vh;
}
.loader > div{
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-text{
    margin-right: 25px;
}
.loader-sidebar {
    height: auto;
    margin-top: 50px;
}
.loader-sidebar  > div{
    max-width: 300px;
    flex-direction: column;
}
.loader-sidebar .loader-text{
    margin: 0;
    margin-bottom: 25px;
    text-align: center;
}