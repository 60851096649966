.skip-message-btn{
    display: block;
    max-width: 150px;
    border: none;
    margin-right: 0;
    margin-left: auto;
    background: #ed4245;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    border-radius: 1px 0px 1px 0px;
}
.skip-message-btn-prev{
    
    border-top: 1px solid #ed4245;
}
.skip-message-btn-next{
    border-bottom: 1px solid #ed4245;
    margin-bottom: 50px;
}
.more-message-btn{
    position: relative;
    display: block;
    max-width: 150px;
    border: none;
    margin: 0 auto;
    background: #5865f2;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    border-radius: 1px 0px 1px 0px;
}
.bot-to-bot-question{
    position: relative;
    background: #5353511f;
    padding: 20px;
}
.bot-to-bot-question::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #53535193;
}
.user-to-bot-question{
    position: relative;
    background: #2f64411f;
    padding: 20px;
}
.user-to-bot-question::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #2f6441ad;
}

.bot-to-user-question::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background:#68682d85;
}
.bot-to-user-question{
    position: relative;
    background: #68682d12;
    padding: 20px;
}

.reply{
    display: flex;
    font-size: 12px;
    border-bottom: 1px solid #ffffff0f;
    margin: 10px 0;
    margin-left: 15px;
}
.message-list > div{
    max-width: 1050px;
    width: 100%;
}
/* .message > div{
    display: flex;
    align-items: flex-end;
} */