.dialog-page-wrapper{
    padding: 25px;
    overflow-y: scroll;
    height: 100vh;
    background-color: rgb(54, 57, 63);
}
.create-dialog-form{
    margin: 0 auto;
    background-color: rgb(54, 57, 63);
}
.create-dialog-form .message{
    position: relative;
    border-radius: 7px;
    background: #393c43;
}
.form-control::placeholder {
    font-size: 12px; 
}
.create-dialog-form .message > div{
    display: block;
}
.message > div{
    display: flex;
    align-items: flex-end;
}