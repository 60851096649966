
.menu-item__img-wrapper{
    display: block;
    position: relative;
    margin: 25px auto; 
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition:  0.3 ease;
} 

.menu-item__img{
    position: relative;
    display: block;
    border: none;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    object-fit: cover;
}


.menu-item__img-wrapper::before{
    content: '';
    position: absolute;
    width: 32px;
    height: 2px;
    left: 5px;
    bottom: -20px;
    background: #2D2F32;
    border-radius: 1px;
}
.menu-item--select::after{
    content: '';
    position: absolute;
    width: 4px;
    height: 50px;
    left: -35px;
    bottom: 0px;
    background: #FFFFFF;
    border-radius: 0px 2px 2px 0px;
   
}

.menu-item__img {
    transition: transform 0.4s ease; 
    transform-origin: center; 
  }
  
  .menu-item__img.scaled {
    opacity: 1;
    transform: scale(1.2); 
  }