


.login-form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}
.login-bg{
  /* background-image: url(/public/img/loginBg.png); */
  background-repeat: no-repeat;
  background-size: cover;
}